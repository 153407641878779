import { useEffect, useState } from "react";
import dayjs from "dayjs";
import arrToStr from "./scripts/arrayToString.js";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

export default function ColumnFilter({
  tableList,
  filterName,
  filterObj,
  setFilterObj,
  getFilterIdList,
  setShowNewModal,
}) {
  const [filterArray, setFilterArray] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [options, setOptions] = useState();
  const arrayFilters = ["levels", "products", "virtualCentres"];
  const dateFilters = ["day", "week"];
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  /* Move blanks option if exists to bottom of list */
  function moveBlanks(sortedFilters) {
    let element = "(Blanks)";
    const fromIndex = sortedFilters.findIndex((x) => x === element);
    sortedFilters.splice(fromIndex, 1);
    const toIndex = sortedFilters.length;
    sortedFilters.splice(toIndex, 0, element);
    return sortedFilters;
  }

  /* On click OK update filter object based on selected */
  function manageFilterObj() {
    if (appliedFilters.length > 0) {
      setFilterObj((state) => ({
        ...state,
        [filterName]: appliedFilters,
      }));
    } else {
      let filterObjCopy = { ...filterObj };
      delete filterObjCopy[filterName];
      setFilterObj((filterObj) => ({
        ...filterObjCopy,
      }));
    }
    setShowNewModal(false);
  }

  /* Retrieves and sets the applied filters based on the filter name. */
  function getAppliedFilters() {
    filterObj.hasOwnProperty(filterName) &&
      setAppliedFilters(filterObj[filterName]);
  }

  /* Modify filter selection */
  const updateColumnFilters = (e) => {
    if (!e.target.checked) {
      setAppliedFilters((currentList) => [...currentList, e.target.value]);
    } else {
      setAppliedFilters((currentList) =>
        currentList.filter((item) => {
          return e.target.value !== item;
        })
      );
    }
  };

  function filterTableList() {
    let idList = [];
    let filterObjCopy = { ...filterObj };
    if (Object.keys(filterObjCopy).length > 0) {
      let filterIndex = Object.keys(filterObjCopy).indexOf(filterName);
      while (Object.keys(filterObjCopy)[filterIndex]) {
        delete filterObjCopy[Object.keys(filterObjCopy)[filterIndex]];
      }
      idList = getFilterIdList(filterObjCopy);
    }
    getFilterList(idList);
  }

  /* Get filters from classlist */
  function getFilterList(idList) {
    let filters = [];
    if (filterName === "published") {
      if (tableList.some((item) => item.class_id)) {
        filters.push("Published");
      }
      if (tableList.some((item) => !item.class_id)) {
        filters.push("Unpublished");
      }
    } else if (filterName === "type") {
      if (tableList.some((item) => item.isClosed?.value)) {
        filters.push("Closed");
      }
      if (tableList.some((item) => !item.isClosed?.value)) {
        filters.push("Open");
      }
    } else if (dateFilters.includes(filterName)) {
      filters = Array.from(
        new Set(
          tableList
            .filter((item) => {
              return !idList.includes(item.temp_id);
            })
            .map((item) =>
              filterName === "day"
                ? dayjs(item.dateStr).format("d")
                : JSON.stringify(dayjs(item.dateStr).startOf("week"))
            )
        )
      );
    } else {
      filters = Array.from(
        new Set(
          tableList
            .filter((item) => {
              return !idList.includes(item.temp_id);
            })
            .map((item) =>
              arrayFilters.includes(filterName)
                ? item[filterName]
                  ? JSON.stringify(item[filterName])
                  : "(Blanks)"
                : item[filterName]?.label
                ? item[filterName].label
                : "(Blanks)"
            )
        )
      );
    }
    let sortedFilters = [...filters].sort((a, b) => (a > b ? 1 : -1));
    /* Move blanks to bottom of filter list */
    sortedFilters.includes("(Blanks)") && moveBlanks(sortedFilters);
    setFilterArray(sortedFilters);
  }

  function handleCheckboxes(mode) {
    let checkboxArray = document.getElementsByClassName("filter-checkbox");
    for (const item of checkboxArray) {
      item.checked = mode;
    }
  }
  function selectAllCheckboxes() {
    setAppliedFilters([]);
    handleCheckboxes(true);
  }
  function unselectAllCheckboxes() {
    /* TODO: remove from selectedClassList */
    setAppliedFilters(
      filterArray.map((item) => {
        return item;
      })
    );

    handleCheckboxes(false);
  }

  useEffect(() => {
    filterTableList();
  }, []);
  useEffect(() => {
    getAppliedFilters();
  }, [filterName]);
  useEffect(() => {
    getOptions();
  }, [filterArray]);

  function formatWeek(itemStr) {
    let item = JSON.parse(itemStr);
    return (
      "Week " +
      dayjs(item).add(1, "day").isoWeek() +
      " (" +
      dayjs(item).startOf("week").add(1, "day").format("DD/MM/YYYY") +
      ")"
    );
  }

  /* Create list of filter options */
  function getOptions() {
    const options =
      filterArray &&
      filterArray.map((item, index) => {
        return (
          <label className="filter-checkbox-label" key={index}>
            <input
              type="checkbox"
              defaultChecked={!appliedFilters?.includes(item)}
              className="filter-checkbox"
              value={item}
              onChange={(e) => updateColumnFilters(e)}
            />
            {arrayFilters.includes(filterName)
              ? item === "(Blanks)"
                ? item
                : arrToStr(JSON.parse(item))
              : filterName === "day"
              ? weekdays[item]
              : filterName === "week"
              ? formatWeek(item)
              : item}
          </label>
        );
      });
    setOptions(options);
  }

  return (
    <div className="column-filter-div">
      <h4> Filter by '{filterName}'</h4>
      <div className="filter-select-all-div">
        <span className="filter-select-all" onClick={selectAllCheckboxes}>
          Select all
        </span>{" "}
        |{" "}
        <span className="filter-select-all" onClick={unselectAllCheckboxes}>
          Unselect all
        </span>
      </div>
      <div>{options && options}</div>
      <div className="modal-button-div">
        <button className="schedule-form-button" onClick={manageFilterObj}>
          OK
        </button>
      </div>
    </div>
  );
}
