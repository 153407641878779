import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./style/selectors.css";
import setCountryLevelCache from "./scripts/setCountryLevelCache.js";

export default function LevelSelector({
  centre,
  classObj,
  handleClassObj,
  isLoaded,
}) {
  const animatedComponents = makeAnimated();
  const [levelOptions, setLevelOptions] = useState([]);

  const getLevelOptions = async () => {
    let levels = [];
    if (centre) {
      levels = JSON.parse(localStorage.getItem("levels"));
      if (!levels) {
        levels = await setCountryLevelCache(centre.centre_id);
      }
      const levelList = levels.map((option) => {
        return {
          value: option.level_id,
          label: option.level_code + " - " + option.level_name,
          level_name: option.level_name,
        };
      });
      setLevelOptions(levelList);
    }
  };

  useEffect(() => {
    getLevelOptions();
  }, [centre]);

  /* Sort levels to display in order regardless of order of selection */
  const sortLevels = (levels) =>
    levels.length > 0 ? [...levels].sort((a, b) => a.value - b.value) : [];

  /* Abbreviate selected option for multiple code only display */
  function shortData(data) {
    return data.map((data) => {
      return {
        value: data.value,
        label: data.label.charAt(0),
        level_name: data.level_name,
      };
    });
  }

  return (
    <Select
      className="schedule-form-target-level-selector"
      value={sortLevels(classObj.levels)}
      isMulti
      placeholder="Level(s)"
      cacheOptions
      isDisabled={!isLoaded}
      components={animatedComponents}
      options={levelOptions}
      onChange={(data) => handleClassObj(shortData(data), { name: "levels" })}
    />
  );
}
