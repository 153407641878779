import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Zoom() {
  const [centreName, setCentreName] = useState();
  const Navigate = useNavigate();

  const getCentreZoomAccount = async () => {
    const centreData = JSON.parse(localStorage.getItem("centre"));
    console.log(centreData);
    const zoomAccount = centreData?.zoom_account;
    setCentreName(centreData?.label);
    if (zoomAccount) {
      Navigate(`/zoom-account/${zoomAccount}`);
    }
  };

  useEffect(() => {
    getCentreZoomAccount();
  }, []);

  return (
    <div>
      <h2>No Zoom account configured</h2>
      <hr />
      <p>There is no Zoom account configured in T2 for {centreName} centre.</p>
    </div>
  );
}
