import { Link } from "react-router-dom";
import dayjs from "dayjs";
import copy_icon from "./images/copy_icon.png";

export default function StrandTable({
  strandList,
  strandType,
  showNewStrandForm,
  confirmDeleteStrand,
}) {
  const strandTypeName = ["Global", "Region", "Country", "Centre"];

  const showStrandList = strandList.map((strand) => {
    return (
      <tr key={strand._id}>
        <td></td>
        <td>
          <Link to={`/strand/${strand._id}`}>{strand.strand_code}</Link>
        </td>
        <td>{strand.level}</td>
        <td>{strandTypeName[strand.strand_type]}</td>
        <td>{strand.strand_name}</td>
        <td className="numeric-column">{strand?.lessons.length}</td>
        <td className="centered-column">
          {dayjs(strand.updatedAt).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="centered-column">
          {dayjs(strand.createdAt).format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{strand.createdBy}</td>
        <td className="centered-column icon-cell">
          {strandType > 0 ? (
            <span
              title="Delete lesson group"
              className="delete-icon"
              onClick={() => confirmDeleteStrand(strand._id)}
            >
              <i className="fa-light fa-square-xmark"></i>
            </span>
          ) : (
            <img
              src={copy_icon}
              title="Make copy of lesson group"
              alt="Copy strand"
              className="copy-icon"
              onClick={() => showNewStrandForm(strand._id)}
            />
          )}
        </td>
      </tr>
    );
  });

  return (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Code</th>
          <td>Level</td>
          <td>Type</td>
          <th>Lesson group</th>
          <th>Lessons</th>
          <th>Updated</th>
          <th>Created</th>
          <th>Created by</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{showStrandList}</tbody>
    </table>
  );
}
