import { useEffect, useState } from "react";
import Select from "react-select";
import NewModal from "./NewModal";
import Spinner from "./Spinner";
import { useUserSettings } from "../../auth/useUserSettings";
import fetchData from "./scripts/fetchData.js";
import "./style/selectors.css";

import setSyllabusLevelCache from "./scripts/setSyllabusLevelCache.js";
import setCountryLevelCache from "./scripts/setCountryLevelCache.js";
import setTeacherCache from "./scripts/setTeacherCache.js";
import setActivityCache from "./scripts/setActivityCache.js";
import setCentreCache from "./scripts/setCentreCache.js";

export default function CentreSelector({ centre, setCentre, loadedSchedule }) {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [countryOptions, setCountryOptions] = useState([]);
  const [centreOptions, setCentreOptions] = useState([]);
  const [userSettings] = useUserSettings();
  const [newModalContent, setnewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);

  /* Content caching - syllabus, teacher, levels, products - when centre is changed */

  async function getSyllabusCache(ctr) {
    let centreId = ctr.centre_id;
    await setCountryLevelCache(centreId);
    let levels = JSON.parse(localStorage.getItem("levels"));
    for (const level of levels) {
      setSyllabusLevelCache(centreId, level.level_id);
    }
  }

  async function getTeacherCache(ctr) {
    await setTeacherCache(ctr.country_id);
    await setActivityCache(ctr.centre_id);
    setCentreCache(ctr);
  }

  /* Function to load country and centre options and selected items */

  const getCountries = async () => {
    const countryAccessList = userSettings.country_access;
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/country-list`;
    const response = await fetchData(url, "GET");
    const accessList = response.filter((country) => {
      return countryAccessList.includes(country.country_id);
    });
    setCountries(accessList);
    let countryList = accessList.map((option) => {
      return {
        value: option.country_code,
        label: option.country_name,
        country_id: option.country_id,
      };
    });
    setCountryOptions(countryList);
  };

  function getUserDefault() {
    let currentCentre = localStorage.getItem("centre");
    if (currentCentre) {
      let centre = JSON.parse(currentCentre);
      let currentCountry = countryOptions.find(
        (country) => country.country_id === centre.country_id
      );
      setSelectedCountry(currentCountry);
      updateCentres(currentCountry);
      // handleSelectedCentre(centre);
    } else if (countryOptions.length > 0) {
      let defaultCountry =
        countryOptions[
          countryOptions
            .map((object) => object.country_id)
            .indexOf(userSettings?.user_settings.default_country)
        ];
      setSelectedCountry(defaultCountry);
      updateCentres(defaultCountry);
    }
  }

  const updateCentres = async (country) => {
    // setCentre("");
    setSelectedCountry(country);
    const country_code = country?.value;
    let centreList = countries.find((item) => {
      return item.country_code === country_code;
    });
    setCentreOptions(
      centreList?.centres?.map((option) => {
        return {
          value: option.centre_id,
          label: option.centre_name + " (" + option.centre_code + ")",
          centre_code: option.centre_code,
          is_virtual: option.is_virtual,
          centre_id: option.centre_id,
          country_id: option.country_id,
          country_code: option.country_code,
          region: option.region,
          cluster: option.cluster ? option.cluster : "NA",
          zoom_account: option?.zoom_account,
        };
      })
    );
  };

  function handleSelectedCentre(data) {
    if (selectedCountry) {
      let currentCentre = JSON.parse(localStorage.getItem("centre"));
      if (data) {
        loadCentre(data, true);
      } else if (
        currentCentre &&
        selectedCountry?.country_id === currentCentre?.country_id
      ) {
        loadCentre(currentCentre, false);
      } else if (
        selectedCountry?.country_id ===
        userSettings?.user_settings.default_country
      ) {
        loadCentre(
          centreOptions[
            centreOptions
              .map((object) => object.centre_id)
              .indexOf(userSettings?.user_settings.default_centre)
          ],
          true
        );
      } else {
        loadCentre("", false);
      }
    }
  }

  async function loadCentre(ctr, reloadCache) {
    if (reloadCache) {
      setnewModalContent(
        <Spinner message="Loading centre data..." timer={false} />
      );
      setShowNewModal(true);
      await getSyllabusCache(ctr);
      await getTeacherCache(ctr);
    }
    setCentre(ctr);
    setShowNewModal(false);
  }

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    getUserDefault();
  }, [countryOptions]);
  useEffect(() => {
    handleSelectedCentre();
  }, [selectedCountry]);

  return (
    <>
      <Select
        className="schedule-form-country-selector"
        name="country"
        value={selectedCountry}
        placeholder="Country"
        cacheOptions
        isSearchable
        isDisabled={loadedSchedule}
        options={countryOptions}
        onChange={(data) => updateCentres(data)}
      />

      <Select
        className="schedule-form-centre-selector"
        name="centre"
        value={centre}
        // defaultOptions
        placeholder="Centre"
        isDisabled={loadedSchedule}
        cacheOptions
        options={centreOptions}
        onChange={(data) => handleSelectedCentre(data)}
      />
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  );
}
