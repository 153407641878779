import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import fetchData from "./scripts/fetchData.js";
import "./style/selectors.css";

export default function ProductSelector({
  centre,
  classObj,
  handleClassObj,
  isLoaded,
}) {
  const [productOptions, setProductOptions] = useState([]);
  const animatedComponents = makeAnimated();

  const sortArrayObjects = (arrayObjects) => {
    let newArrayObject = [];
    if (arrayObjects.length > 0) {
      newArrayObject = arrayObjects.sort((arrayObject1, arrayObject2) => {
        return arrayObject1.value - arrayObject2.value;
      });
    }
    return newArrayObject;
  };

  const getProductOptions = async () => {
    if (centre) {
      const centreId = centre.centre_id;
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/products/${centreId}`;
      const results = await fetchData(url, "GET");
      if (results) {
        const optionsList = results.data.map((option) => {
          return {
            value: option.product_id,
            label: option.product_code + " - " + option.product_name,
            product_name: option.product_name,
          };
        });
        setProductOptions(optionsList);
      }
    }
  };

  useEffect(() => {
    getProductOptions();
  }, [centre]);

  /* Abbreviate selected option for multiple code only display */
  function shortData(data) {
    return data.map((data) => {
      return {
        value: data.value,
        label: data.label.substring(0, 3),
        product_name: data.product_name,
      };
    });
  }

  return (
    <Select
      className="schedule-form-product-selector"
      isMulti
      value={sortArrayObjects(classObj.products)}
      placeholder="Product(s)"
      cacheOptions
      isDisabled={!isLoaded}
      options={productOptions}
      components={animatedComponents}
      onChange={(data) => handleClassObj(shortData(data), { name: "products" })}
    />
  );
}
