import { useState } from "react";

import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "./auth/PrivateRoute";

import Header from "./components/layout/Header";

import Schedule from "./components/schedule/Schedule";
import ScheduleArchive from "./components/schedule/ScheduleArchive";
import Teachers from "./components/schedule/Teachers";
import TeacherView from "./components/schedule/TeacherView";
import Content from "./components/schedule/Content";
import Strands from "./components/schedule/Strands";
import Strand from "./components/schedule/Strand";
import Unauthorised from "./components/schedule/Unauthorised";
import NewModal from "./components/schedule/NewModal";
import News from "./components/schedule/News";
import ZoomSubAccounts from "./components/zoom/ZoomSubAccounts";
import ZoomSubAccount from "./components/zoom/ZoomSubAccount";
import Zoom from "./components/schedule/Zoom";

/* Test files */
import ObjectTests from "./components/tests/ObjectTests";

import Login from "./account/Login";
import LoginObs from "./account/LoginObs";
import PasswordReset from "./account/PasswordReset";
import CreateAdminAccount from "./account/CreateAdminAccount";
import AdminAccounts from "./account/AdminAccounts";
import "./App.css";

function App() {
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState();

  function showNewsModal() {
    setNewModalContent(<News setShowNewModal={setShowNewModal} />);
    setShowNewModal(true);
  }

  return (
    <div className="containerDiv">
      <Header showNewsModal={showNewsModal} />
      {/* <Navbar /> */}
      <main>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="login-obs/:obstoken" element={<LoginObs />} />

          <Route element={<PrivateRoute />}>
            <Route path="schedule" element={<Schedule />} />
            <Route path="schedule-archive" element={<ScheduleArchive />} />
            <Route path="teachers" element={<Teachers />} />
            <Route path="teacher-view" element={<TeacherView />} />
            <Route path="content" element={<Content />} />
            <Route path="strands" element={<Strands />} />
            <Route path="strand/:strandId" element={<Strand />} />
            <Route path="new-admin-account" element={<CreateAdminAccount />} />
            <Route path="admin-accounts" element={<AdminAccounts />} />
            <Route path="unauthorised" element={<Unauthorised />} />
            <Route path="password-reset" element={<PasswordReset />} />
            <Route path="zoom-accounts" element={<ZoomSubAccounts />} />
            <Route path="zoom" element={<Zoom />} />
            <Route
              path="zoom-account/:accountId"
              element={<ZoomSubAccount />}
            />
            {/* Test pages */}
            <Route path="object-tests" element={<ObjectTests />} />
          </Route>
        </Routes>
      </main>
      <footer>
        <div className="footer-left-div">© British Council 2024</div>
        <div className="footer-right-div">
          <span className="version-link">Version 0.14.8</span>
        </div>
      </footer>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}

export default App;
