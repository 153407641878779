import React from "react";
import { Link, useNavigate } from "react-router-dom";
import t2_logo from "../schedule/images/t2_logo.png";
import encrypt from "../schedule/scripts/encrypt";

export default function Header({ showNewsModal }) {
  const navigate = useNavigate();
  const userData = localStorage.getItem("user_settings")
    ? JSON.parse(encrypt("decrypt", localStorage.getItem("user_settings")))
    : [];
  let userRoles = userData?.roles;

  function logOff() {
    localStorage.clear();
    navigate("/login");
  }

  return (
    <header>
      <div className="header-title-div">
        <img src={t2_logo} alt="T2" className="t2-logo" />
        {/* <h2>T2</h2> */}
      </div>
      {userRoles && (
        <div className="header-links-div">
          <Link to="/schedule" className="header-link">
            Scheduler
          </Link>{" "}
          |
          <Link to="/strands" className="header-link">
            Lesson groups
          </Link>{" "}
          |
          {userRoles?.includes(28) && (
            <>
              <Link to="/teacher-view" className="header-link">
                Teachers
              </Link>{" "}
              |
            </>
          )}
          <Link to="/schedule-archive" className="header-link">
            Archive
          </Link>{" "}
          |
          {userRoles?.includes(28) && (
            <>
              <Link to="/zoom-accounts" className="header-link">
                Zoom admin
              </Link>{" "}
              |
              <Link to="/zoom" className="header-link">
                Zoom
              </Link>{" "}
              |
            </>
          )}
          <Link onClick={showNewsModal} className="header-link">
            News
          </Link>{" "}
          |
          {userRoles?.includes(5) && (
            <>
              <Link
                to="/admin-accounts"
                className="header-link"
                target="_blank"
              >
                Users
              </Link>{" "}
              |
            </>
          )}
          <Link to="/" onClick={logOff} className="header-link">
            Log off
          </Link>
        </div>
      )}
    </header>
  );
}
