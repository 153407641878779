import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import NewModalCloseButton from "./NewModalCloseButton";
import fetchData from "./scripts/fetchData.js";

export default function CentreCalendar({
  centre,
  setCentreCalendar,
  setDisabledDates,
  setShowNewModal,
}) {
  const today = dayjs(new Date()).format();
  const [startDate, setStartDate] = useState(
    dayjs(today).startOf("date").toDate()
  );
  const [duration, setDuration] = useState(1);
  const [calendar, setCalendar] = useState([]);
  const [disabledDateList, setDisabledDateList] = useState([]);
  const [title, setTitle] = useState("");
  const centre_code = centre?.centre_code;
  const titleRef = useRef(null);

  const calendarItem = {
    centre: centre_code,
    start_date: dayjs(startDate),
    duration: duration,
    end_date: dayjs(startDate).add(duration, "day"),
    title: title,
    is_non_teaching: true,
  };

  /* get future calendar of non-teaching days for selected centre */
  const getCentreCalendar = async () => {
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/calendar/${centre_code}`;
      const results = await fetchData(url);
      setCalendar(results);
      setCentreCalendar(results);
      let disabledDateList = results
        .filter((item) => item.is_non_teaching)
        .flatMap((item) =>
          Array.from(
            { length: item.duration },
            (_, i) =>
              new Date(
                dayjs(item.start_date).add(i, "day").format("YYYY/MM/DD")
              )
          )
        );
      setDisabledDateList(disabledDateList);
      setDisabledDates(disabledDateList);
    } catch (error) {
      console.log(error);
    }
  };

  const addCalendarItem = async (e) => {
    e.preventDefault();
    if (!title?.trim()) {
      titleRef.current.focus();
    } else {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/calendar`;
      await fetchData(url, "POST", calendarItem);
      setTitle("");
      getCentreCalendar();
    }
  };

  const updateCalendarNTD = async (e, calendarItemId) => {
    // e.preventDefault();
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/calendar-ntd/${calendarItemId}`;
    await fetchData(url, "PUT", { is_non_teaching: e.target.checked });
    getCentreCalendar();
  };

  const deleteCalendarItem = async (calendarItemId) => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/calendar/${calendarItemId}`;
    await fetchData(url, "DELETE");
    getCentreCalendar();
  };

  useEffect(() => {
    getCentreCalendar();
  }, [centre]);

  const calendarList = calendar?.map((item) => {
    return (
      <tr key={item._id}>
        <td>{item.title}</td>
        <td>{dayjs(item.start_date).format("DD/MM/YYYY")}</td>
        <td className="schedule-table-centred-column">
          {dayjs(item.start_date).format("ddd")}
        </td>
        <td className="schedule-table-right-aligned-column">{item.duration}</td>
        <td>{dayjs(item.end_date).format("DD/MM/YYYY")}</td>
        <td className="schedule-table-centred-column">
          <input
            type="checkbox"
            className="calendar-ntd-checkbox"
            defaultChecked={item.is_non_teaching}
            // checked={}
            value={item._id}
            onChange={(e) => updateCalendarNTD(e, item._id)}
          />
        </td>
        <td className="schedule-table-centred-column">
          <span
            title="Delete event"
            className="delete-icon"
            onClick={() => deleteCalendarItem(item._id)}
          >
            <i className="fa-light fa-square-xmark"></i>
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="calendar-div">
      <h2>Centre calendar - {centre_code}</h2>
      <form className="schedule-form">
        <DatePicker
          todayButton="Today"
          dateFormat="dd/MM/yyyy"
          calendarStartDay={1}
          className="schedule-form-date"
          minDate={new Date(today)}
          selected={startDate}
          highlightDates={disabledDateList}
          onChange={(startDate) => setStartDate(startDate)}
          monthsShown={3}
        />
        <input
          name="duration"
          id="duration"
          value={duration}
          placeholder="Days"
          className="calendar-duration"
          onChange={(e) => setDuration(e.target.value)}
        />
        <input
          ref={titleRef}
          name="title"
          id="title"
          className="schedule-form-title"
          placeholder="Event name ..."
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
        <button
          className="schedule-form-button"
          onClick={(e) => {
            addCalendarItem(e);
          }}
        >
          Add to calendar
        </button>
      </form>
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Event name</th>
            <th>Start date</th>
            <th>Day</th>
            <th>Dur.</th>
            <th>End date</th>
            <th>NTD</th>
            <th>
              <span className="delete-icon">
                <i className="fa-light fa-square-xmark header-icon"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>{calendarList}</tbody>
      </table>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
