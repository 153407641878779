import { useEffect, useState, useRef } from "react";
import LessonHistory from "./LessonHistory";
import NewModal from "./NewModal";
import Help from "./Help";
import getLessonFromCache from "./scripts/getLessonFromCache.js";
import fetchData from "./scripts/fetchData.js";

import module_icon from "./images/module_icon.svg";
import info_icon from "./images/info_icon.png";
import "./style/selectors.css";

export default function Lessons({
  centre,
  classObj,
  setClassObj,
  handleClassObj,
  lessons,
  lessonArray,
  setLessonArray,
  closeModal,
  getModules,
  selectedLessonIdList,
}) {
  const [isModule, setIsModule] = useState();
  const [moduleCode, setModuleCode] = useState();
  const [moduleType, setModuleType] = useState(0);
  const [classType, setClassType] = useState(1);
  const [moduleLessons, setModuleLessons] = useState([]);
  const [arrayLessons, setArrayLessons] = useState([]);
  const [moduleTitle, setModuleTitle] = useState();
  const [modulePathway, setModulePathway] = useState();
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const pathwayModules = [
    "Work",
    "Social",
    "Academic",
    "Business Skills",
    "Exam preparation",
  ];

  /* Drag and drop functions */

  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e) => {
    dragItem.current = e.target.id;
  };
  const dragEnter = (e) => {
    dragOverItem.current = e.currentTarget.id;
  };

  const drop = () => {
    let reorderedLessons = JSON.parse(JSON.stringify(moduleLessons));
    const dragItemContent = reorderedLessons[dragItem.current];
    reorderedLessons.splice(dragItem.current, 1);
    reorderedLessons.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setModuleLessons(reorderedLessons);
    setLessonArray(
      reorderedLessons.filter((lesson) => {
        return arrayLessons.some((item) => item.lesson_id === lesson.lesson_id);
      })
    );
  };

  const getHelp = async (helpId) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/help/${helpId}`;
    const response = await fetchData(url, "GET");
    setNewModalContent(
      <Help helpTitle={response.help_title} helpText={response.help_text} />
    );
    setShowNewModal(true);
  };

  /* Module functions */

  function checkModule() {
    if (classObj.module) {
      setIsModule(true);
      setModuleCode(classObj.module?.label);
      setModuleType(Number(classObj.module?.value.split(":")[2]));
      setClassType(Number(classObj.module?.value.split(":")[3]));
    }
  }

  const handleIsModule = (e) => {
    if (e.target.checked) {
      setIsModule(true);
      let newModuleCode = getNewModuleCode();
      handleModule(newModuleCode);
    } else {
      setClassObj((state) => ({ ...state, module: "" }));
      setIsModule(false);
      setModuleCode();
    }
  };

  const handleModule = (code) => {
    if (!code) {
      code = classObj.module?.label;
    }
    let newCode = (moduleType === 1 ? "CL" : "OP") + "M" + code?.substring(3);
    let newValue =
      newCode +
      ":" +
      classObj.theme.theme_id +
      ":" +
      moduleType +
      ":" +
      classType;
    if (code) {
      handleClassObj(
        {
          value: newValue,
          label: newCode,
        },
        { name: "module" },
        false
      );
      handleModuleClasses(classType);
    }
  };

  function handleModuleClasses(val) {
    setClassObj((state) => ({
      ...state,
      isClosed: { value: val === 1 ? true : false },
    }));
  }

  const getNextNumber = () => {
    let moduleList = getModules();
    if (moduleList.length > 0) {
      let newList = moduleList.map((item) => {
        return Number(item.split(":")[0].replace(/[^0-9]/g, ""));
      });
      return Math.max(...newList);
    } else {
      return 0;
    }
  };

  const getNewModuleCode = () => {
    let nextNum = getNextNumber();
    let newCode = (moduleType === 1 ? "CL" : "OP") + "M" + (nextNum + 1);
    setModuleCode(newCode);
    return newCode;
  };

  /* End of module functions */

  /* Lesson functions */

  const getLessons = async (lessons) => {
    let updatedLessons = [];
    for (const lesson of lessons) {
      let nextLesson = getLessonFromCache(lesson);
      updatedLessons.push(nextLesson);
    }
    // updatedLessons = await getAllLessonHistories(updatedLessons);
    setModuleLessons(updatedLessons);
  };

  const getModuleLessons = async () => {
    let url = "";
    // let excludedArray = [];
    if (classObj.theme) {
      setModuleLessons(lessons);
      setModuleTitle(classObj.theme.label);
      setModulePathway(classObj.pathway.label);
    } else if (classObj.strand) {
      const strandId = classObj.strand.value;
      url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/strand/${strandId}`;
      const response = await fetchData(url, "GET");
      getLessons(response.lessons);
      setModuleTitle(classObj.strand.label);
    }
  };

  useEffect(() => {
    setArrayLessons(lessonArray);
  }, [lessonArray]);

  useEffect(() => {
    checkModule();
  }, []);
  useEffect(() => {
    getModuleLessons();
  }, [classObj]);
  useEffect(() => {
    handleModule(moduleCode);
  }, [classType, moduleType]);

  const showLessonHistory = async (lessonId, lessonTitle, classType) => {
    setNewModalContent(
      <LessonHistory
        centre={centre}
        lessonId={lessonId}
        lessonTitle={lessonTitle}
        classType={classType}
        teacherName={classObj.teacher?.label}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  };

  function handleLessonSelection(e, lesson_id) {
    const lesson = Number(lesson_id);
    if (!e.target.checked) {
      setArrayLessons((currentList) =>
        currentList.filter((item) => {
          return item.lesson_id !== lesson;
        })
      );
    } else {
      setArrayLessons((currentList) => [
        ...currentList,
        moduleLessons.find((obj) => {
          return obj.lesson_id === lesson_id;
        }),
      ]);
    }
  }

  function confirmChanges() {
    //get an array of lesson_ids from arrayLessons
    let lessonIds = arrayLessons.map((lesson) => lesson.lesson_id);
    let newLessonArrayLessons = moduleLessons.filter((lesson) =>
      lessonIds.includes(lesson.lesson_id)
    );
    setLessonArray(newLessonArrayLessons);
    closeModal();
  }

  const lessonList = moduleLessons?.map((lesson, index) => {
    return (
      <tr
        key={index}
        id={index}
        onDragStart={(e) => dragStart(e)}
        onDragEnter={(e) => dragEnter(e)}
        onDragEnd={drop}
        draggable={arrayLessons.some(
          (item) => item.lesson_id === lesson.lesson_id
        )}
      >
        <td className="centered-column">
          <input
            type="checkbox"
            name={lesson.lesson_id}
            value={lesson.lesson_id}
            className="strand-checkbox"
            //check if the lesson_id is in arrayLessons
            checked={arrayLessons.some(
              (item) => item.lesson_id === lesson.lesson_id
            )}
            onChange={(e) => handleLessonSelection(e, lesson.lesson_id)}
          />
        </td>
        <td className="centered-column">{lesson.theme_code}</td>
        <td>{lesson.pathway}</td>
        <td>{lesson.content}</td>
        <td>{lesson.theme_name}</td>
        <td className="centered-column">{lesson.lesson_code}</td>
        <td>{lesson.lesson_title}</td>
        <td className="centered-column icon-cell">
          <span
            title="Show lesson history"
            className="history-icon"
            onClick={() =>
              showLessonHistory(lesson.lesson_id, lesson.lesson_title, "lesson")
            }
          >
            <i className="fa-regular fa-clock-rotate-left"></i>
          </span>
        </td>
      </tr>
    );
  });
  return (
    <div className="lessons-div">
      <h2>
        {moduleTitle}
        {modulePathway && " (" + modulePathway + ")"}
      </h2>
      {pathwayModules.includes(modulePathway) && (
        <img
          src={module_icon}
          alt="Configure lessons"
          className="module-icon"
        />
      )}
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div">
          {pathwayModules.includes(modulePathway) && (
            <>
              <label htmlFor="isModule" className="filter-checkbox-label">
                <input
                  type="checkbox"
                  id="isModule"
                  name="isModule"
                  checked={isModule || false}
                  className="filter-checkbox"
                  onChange={(e) => handleIsModule(e)}
                />
                Module {classObj.module && `(${classObj.module.label})`}
              </label>
              {isModule && (
                <div className="module-form-wrapper">
                  <div className="module-form-div">Module type:</div>
                  <div className="module-form-div">
                    <label className="module-radio-label">
                      <input
                        type="radio"
                        name="module_type"
                        checked={moduleType === 0}
                        className="filter-checkbox"
                        onChange={() => setModuleType(0)}
                      />
                      Open
                    </label>
                  </div>
                  <div className="module-form-div">
                    <label className="module-radio-label">
                      <input
                        type="radio"
                        name="module_type"
                        checked={moduleType === 1}
                        className="filter-checkbox"
                        onChange={() => setModuleType(1)}
                      />
                      Closed
                    </label>
                  </div>
                  <div className="module-form-div">
                    <img
                      src={info_icon}
                      className="module-info-icon"
                      alt="Information icon"
                      onClick={() => getHelp(1)}
                    />
                  </div>
                  <div className="module-form-div">Module classes:</div>
                  <div className="module-form-div">
                    <label className="module-radio-label">
                      <input
                        type="radio"
                        name="moduleClasses"
                        checked={classType === 0}
                        className="filter-checkbox"
                        onChange={() => setClassType(0)}
                      />
                      Open
                    </label>
                  </div>
                  <div className="module-form-div">
                    <label className="module-radio-label">
                      <input
                        type="radio"
                        name="moduleClasses"
                        checked={classType === 1}
                        className="filter-checkbox"
                        onChange={() => setClassType(1)}
                      />
                      Closed
                    </label>
                  </div>
                  <div className="module-form-div">
                    <img
                      src={info_icon}
                      className="module-info-icon"
                      alt="Information icon"
                      onClick={() => getHelp(2)}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className="schedule-container-right-div">
          <div className="schedule-container-info-div">
            Level: {classObj.contentLevel?.label}
          </div>
          <div className="schedule-container-info-div">
            Lessons: {moduleLessons?.length}
          </div>
          <div className="schedule-container-info-div">
            Selected: {arrayLessons.length}
          </div>
          <div></div>
        </div>
      </div>
      <table className="calendar-table">
        <thead>
          <tr>
            <th></th>
            <th>Theme</th>
            <th>Pathway</th>
            <th>Type</th>
            <th>Theme name</th>
            <th>Lesson No.</th>
            <th>Lesson title</th>
            <th>
              <span title="Lesson history">
                <i className="fa-regular fa-clock-rotate-left header-icon"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>{lessonList}</tbody>
      </table>
      <div className="modal-button-div">
        {JSON.stringify(lessonArray) !== JSON.stringify(arrayLessons) && (
          <button
            className="schedule-form-button"
            onClick={() => confirmChanges()}
          >
            Confirm
          </button>
        )}
        <button className="schedule-form-button" onClick={() => closeModal()}>
          Close
        </button>
      </div>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
