import React, { useState, useRef, useEffect } from "react";
import Select from "react-select";
import { useUserSettings } from "../../auth/useUserSettings";
import { createTimeSlots } from "./scripts/createTimeSlots";
import "./style/selectors.css";

export default function TeacherViewConfig({
  dayParts,
  handleDayPartChange,
  setShowNewModal,
}) {
  const [timeslotOptions, setTimeslotOptions] = useState([]);
  const [dayPartArray, setDayPartArray] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState();
  const [userSettings] = useUserSettings();
  const timeslotRef = useRef();

  function addDayPart(e) {
    e.preventDefault();
    if (!selectedTimeslot) {
      timeslotRef.current.focus();
    } else {
      if (!dayPartArray.includes(selectedTimeslot.value)) {
        let dayPartArrayCopy = [...dayPartArray, selectedTimeslot.value];
        dayPartArrayCopy.sort((a, b) => a - b);
        setDayPartArray(dayPartArrayCopy);
        handleDayPartChange(dayPartArrayCopy);
      }
    }
  }

  function deleteDayPart(index) {
    let dayPartArrayCopy = [...dayPartArray];
    dayPartArrayCopy.splice(index, 1);
    setDayPartArray(dayPartArrayCopy);
    handleDayPartChange(dayPartArrayCopy);
  }

  const convertMinsToTimeSlot = (timeslot) => {
    let minutes = timeslot % 60;
    let hours = Math.floor(timeslot / 60);
    let output =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0");
    return output;
  };

  const displayDayParts = dayPartArray
    .slice(0, dayPartArray.length - 1)
    .map((dayPart, index) => {
      return (
        <tr key={dayPart}>
          <td>
            {convertMinsToTimeSlot(dayPart)} -{" "}
            {convertMinsToTimeSlot(dayPartArray[index + 1])}
          </td>
          <td className="teacher-config-delete-col">
            <span
              title="Delete timeslot"
              className="delete-icon"
              onClick={() => deleteDayPart(index)}
            >
              <i className="fa-light fa-square-xmark"></i>
            </span>
          </td>
        </tr>
      );
    });

  useEffect(() => {
    // const { user_settings } = userSettings;
    // const slots = createTimeSlots(
    //   user_settings?.first_timeslot,
    //   user_settings?.last_timeslot,
    //   user_settings?.timeslot_interval || 5
    // );
    const slots = createTimeSlots(0, 1434, 5);
    setTimeslotOptions(slots);
  }, [userSettings]);

  useEffect(() => {
    setDayPartArray(dayParts);
  }, [dayParts]);

  return (
    <div>
      <h3>Teacher table display</h3>
      <form className="schedule-form">
        <Select
          ref={timeslotRef}
          className="schedule-form-timeslot-selector"
          value={selectedTimeslot}
          placeholder="Time"
          cacheOptions
          isSearchable
          isClearable
          options={timeslotOptions}
          onChange={(data) => setSelectedTimeslot(data)}
        />
        <button className="schedule-form-button" onClick={(e) => addDayPart(e)}>
          Add
        </button>
      </form>
      <table className="calendar-table">
        <thead>
          <tr>
            <th>Time slot range</th>
            <th>
              <span className="delete-icon">
                <i className="fa-light fa-square-xmark header-icon"></i>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>{displayDayParts}</tbody>
      </table>
      <div className="modal-button-div">
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
}
