import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import NewModal from "./NewModal";
import LessonHistory from "./LessonHistory";
import dayjs from "dayjs";
import { useUserSettings } from "../../auth/useUserSettings";
import setSyllabusLevelCache from "./scripts/setSyllabusLevelCache.js";
import checkHistoryCache from "./scripts/checkHistoryCache.js";
import addHistoryData from "./scripts/addHistoryData.js";
import "./style/selectors.css";

export default function Content({
  centre,
  addLessonsToStrand,
  lessonCount,
  selectMode,
  strandLessons,
  closeContentModal,
}) {
  const [lessons, setLessons] = useState([]);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [tempStrandLessons, setTempStrandLessons] = useState(strandLessons);
  const [searchTerm, setSearchTerm] = useState();
  const [selectedLevel, setSelectedLevel] = useState(() =>
    JSON.parse(localStorage.getItem("selectedLevel"))
  );
  const [pathwayOptions, setPathwayOptions] = useState([]);
  const [selectedPathway, setSelectedPathway] = useState();
  const [themeOptions, setThemeOptions] = useState();
  const [selectedTheme, setSelectedTheme] = useState();
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [altKeyUsed, setAltKeyUsed] = useState(false);
  const [userSettings] = useUserSettings();
  const ref = useRef(null);

  const getPathwayOptions = async (sortedLessons) => {
    let tempLessons = JSON.parse(JSON.stringify(sortedLessons));
    const pathwayList = Array.from(
      new Set(tempLessons.map((item) => item.pathway))
    );
    setPathwayOptions(
      pathwayList.map((pathway) => {
        return {
          value: pathway,
          label: pathway,
        };
      })
    );
    setSelectedPathway("");
  };

  function getThemeOptions(pathway, sortedLessons) {
    let tempLessons = JSON.parse(JSON.stringify(sortedLessons));
    if (pathway) {
      tempLessons = tempLessons.filter((lesson) => {
        return lesson.pathway === pathway;
      });
    }
    const themeList = Array.from(
      new Set(
        tempLessons.map((item) => item.theme_code + ": " + item.theme_name)
      )
    );
    setThemeOptions(
      themeList.map((theme) => {
        return {
          value: theme.split(":")[0],
          label: theme,
        };
      })
    );
    setSelectedTheme("");
  }

  /* Sort lessons by theme code and seq (lesson order column) */
  function sortLessons(lessonList) {
    let sortedLessons = [...lessonList].sort(
      (a, b) => a.theme_code.localeCompare(b.theme_code) || a.seq - b.seq
    );
    return sortedLessons;
  }

  async function getLessonHistories(lessonList) {
    let lessonHistories = await checkHistoryCache(
      lessonList,
      centre.country_id,
      userSettings.user_settings.history_since.toString()
    );
    let returnLessonList = await addHistoryData(lessonList, lessonHistories);
    return returnLessonList;
  }

  /* Filter lessons by pathway or theme */
  async function filterLessons(data, selector) {
    if (selectedLevel) {
      const levelId = Number(selectedLevel?.level_id);
      let filteredLessons = [];
      if (selector.name === "theme") {
        let themeFilter = data.value;
        setSelectedTheme(data);
        let tempLessons = JSON.parse(localStorage.getItem("level_" + levelId));
        filteredLessons = tempLessons.filter((lesson) => {
          return lesson.theme_code === themeFilter;
        });
      } else if (selector.name === "pathway") {
        setSelectedPathway(data);
        let pathwayFilter = data.value;
        let tempLessons = JSON.parse(localStorage.getItem("level_" + levelId));
        filteredLessons = tempLessons.filter((lesson) => {
          return lesson.pathway === pathwayFilter;
        });
        getThemeOptions(pathwayFilter, filteredLessons);
      }
      let lessonsWithHistory = await getLessonHistories(filteredLessons);
      let sortedLessons = sortLessons(lessonsWithHistory);
      setLessons(sortedLessons);
    }
  }

  async function getLessonsByLevel() {
    selectedLevel && setIsLoading(true);
    setSelectedPathway("");
    setSelectedTheme("");
    let lessonArray = [];
    if (selectedLevel) {
      const levelId = Number(selectedLevel?.level_id);
      lessonArray = JSON.parse(localStorage.getItem("level_" + levelId));
      if (!lessonArray) {
        const centre = JSON.parse(localStorage.getItem("centre"));
        lessonArray = await setSyllabusLevelCache(centre.centre_id, levelId);
      }
      if (lessonArray) {
        let lessonsWithHistory = await getLessonHistories(lessonArray);
        let sortedLessons = sortLessons(lessonsWithHistory);
        setLessons(sortedLessons);
        getPathwayOptions(sortedLessons);
        getThemeOptions(null, sortedLessons);
        setIsLoading(false);
      }
    }
  }

  /* Display selected lesson history in modal */
  const showLessonHistory = async (lessonId, lessonTitle) => {
    setNewModalContent(
      <LessonHistory
        centre={centre}
        lessonId={lessonId}
        lessonTitle={lessonTitle}
        classType="lesson"
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  };

  function getClickType(e) {
    // setCtrlKeyUsed(e.ctrlKey);
    setAltKeyUsed(e.altKey);
  }

  useEffect(() => {
    getLessonsByLevel();
  }, [selectedLevel, lessonCount]);

  useEffect(() => {
    const element = ref.current;
    element.addEventListener("click", (e) => getClickType(e));
    return () => {
      element.removeEventListener("click", (e) => getClickType(e));
    };
  }, []);

  function selectLessonRange(lesson_id) {
    let startPos = lessons.findIndex(
      (item) => item.lesson_id === selectedLessons[selectedLessons.length - 1]
    );
    let endPos = lessons.findIndex((item) => item.lesson_id === lesson_id);
    if (startPos > endPos) {
      [startPos, endPos] = [endPos - 1, startPos];
    }
    for (let i = startPos + 1; i <= endPos; i++) {
      setSelectedLessons((current) => [...current, lessons[i].lesson_id]);
    }
  }

  function addToSelectedLessons(lesson_id) {
    if (selectedLessons.includes(lesson_id)) {
      setSelectedLessons((currentList) =>
        currentList.filter((id) => {
          return id !== lesson_id;
        })
      );
    } else if (altKeyUsed) {
      selectLessonRange(lesson_id);
    } else {
      setSelectedLessons((current) => [...current, lesson_id]);
    }
  }

  function clearSelectedLessons() {
    setSelectedLessons([]);
  }

  function addLessons() {
    let mergedLessons = tempStrandLessons
      ? [...tempStrandLessons, ...selectedLessons]
      : [...selectedLessons];
    setTempStrandLessons(mergedLessons);
    addLessonsToStrand(mergedLessons);
    setSelectedLessons([]);
  }

  function checkSearchTerm(content) {
    if (searchTerm && searchTerm.length > 2) {
      let newSearchTerm = searchTerm.toUpperCase();
      let i = 0;
      for (const item of content) {
        if (item.toUpperCase().indexOf(newSearchTerm) > -1) {
          i++;
        }
      }
      if (i > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  const levelOptions = [
    { value: "1", label: "Elementary" },
    { value: "2", label: "Pre-intermediate" },
    { value: "3", label: "Intermediate" },
    { value: "4", label: "Upper intermediate" },
    { value: "5", label: "Advanced" },
    { value: "7", label: "C2" },
  ];

  const lessonList = lessons?.map((lesson) => {
    return (
      <tr
        className={`${
          selectMode
            ? selectedLessons?.includes(lesson.lesson_id)
              ? "schedule-table-row-selected"
              : tempStrandLessons?.includes(lesson.lesson_id)
              ? "schedule-table-row-strand-lesson"
              : null
            : null
        } ${
          checkSearchTerm([
            lesson.lesson_title,
            lesson.theme_name,
            lesson.lesson_code,
            lesson.theme_code,
          ]) && "search-filtered"
        }`}
        key={lesson.lesson_id}
      >
        <td onClick={() => addToSelectedLessons(lesson.lesson_id)}></td>
        <td>{lesson.content}</td>
        <td>{lesson.pathway}</td>
        <td className="centered-column">{lesson.theme_code}</td>
        <td>{lesson.theme_name}</td>
        <td className="centered-column">{lesson.lesson_code}</td>
        <td>{lesson.lesson_title}</td>
        <td>
          {lesson.last_class &&
            `${dayjs(lesson.last_class).format("DD/MM/YYYY HH:mm")} (${
              lesson.past_class_count
            })`}
        </td>
        <td>
          {lesson.next_class &&
            `${dayjs(lesson.next_class).format("DD/MM/YYYY HH:mm")} (${
              lesson.future_class_count
            })`}
        </td>
        <td className="centered-column icon-cell">
          <span
            title="Show lesson history"
            className="history-icon"
            onClick={() =>
              showLessonHistory(lesson.lesson_id, lesson.lesson_title)
            }
          >
            <i className="fa-regular fa-clock-rotate-left"></i>
          </span>
        </td>
      </tr>
    );
  });

  return (
    <div className="lessons-div">
      <div className="syllabus-head">
        <div className="schedule-container-div">
          <div className="schedule-container-left-div">
            <h2 className="syllabus-header">MyClass syllabus</h2>
          </div>
          <div className="schedule-container-right-div">
            {selectMode && (
              <>
                <button className="schedule-form-button" onClick={addLessons}>
                  Add selected
                </button>
                <button
                  className="schedule-form-button"
                  onClick={clearSelectedLessons}
                >
                  Clear selection
                </button>
                <button
                  className="schedule-form-button"
                  onClick={closeContentModal}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
        <hr />
        <div className="schedule-container-div">
          <div className="schedule-container-left-div">
            <div className="content-selectors-div">
              <Select
                className="schedule-form-level-name-selector"
                value={selectedLevel}
                placeholder="Level"
                cacheOptions
                options={levelOptions}
                onChange={(data) => setSelectedLevel(data)}
              />
              <Select
                className="schedule-form-content-pathway-selector"
                value={selectedPathway}
                placeholder="All pathways"
                cacheOptions
                options={pathwayOptions}
                onChange={(data) => filterLessons(data, { name: "pathway" })}
              />
              <Select
                className="schedule-form-content-theme-selector"
                value={selectedTheme}
                placeholder="All themes"
                cacheOptions
                options={themeOptions}
                onChange={(data) => filterLessons(data, { name: "theme" })}
              />
            </div>
          </div>

          <div className="schedule-container-right-div">
            {/* <div className=""> */}
            <input
              type="text"
              placeholder="Search lesson/theme title..."
              id="searchTerm"
              name="searchTerm"
              className="search-term-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            {/* </div> */}
          </div>
        </div>
      </div>
      <table ref={ref}>
        <thead>
          <tr>
            <th onClick={clearSelectedLessons}></th>
            <th>Type</th>
            <th>Pathway</th>
            <th>Theme code</th>
            <th>Theme name</th>
            <th>Lesson code</th>
            <th>Lesson title</th>
            <th>Last class</th>
            <th>Next class</th>
            <th>
              <i className="fa-regular fa-clock-rotate-left header-icon"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td className="loading-div" colSpan={10}>
                <h4>Loading ...</h4>
              </td>
            </tr>
          ) : (
            lessonList
          )}
        </tbody>
      </table>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
