import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import fetchData from "./scripts/fetchData.js";
import "./style/selectors.css";

export default function VirtualCentreSelector({
  centre,
  classObj,
  handleClassObj,
  isLoaded,
}) {
  const [virtualCentreOptions, setVirtualCentreOptions] = useState([]);
  const animatedComponents = makeAnimated();

  const getVirtualCentreOptions = async () => {
    let currentRegion = centre?.region;
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/virtual-centres`;
    const response = await fetchData(url, "GET");
    const optionsList = response
      .filter((centre) => centre.region === currentRegion)
      .map((option) => {
        return {
          value: option.centre_id,
          label: option.centre_code + " - " + option.country_name,
          country_name: option.country_name,
        };
      });
    optionsList.sort((a, b) => a.country_name.localeCompare(b.country_name));
    setVirtualCentreOptions(optionsList);
  };
  useEffect(() => {
    getVirtualCentreOptions();
  }, []);

  /* Abbreviate selected option for multiple code only display */
  function shortData(data) {
    return data.map((data) => {
      return {
        value: data.value,
        label: data.label.substring(0, 3),
        country_name: data.country_name,
      };
    });
  }

  return (
    <Select
      className="schedule-form-virtual-centre-selector"
      isMulti
      value={classObj.virtualCentres}
      placeholder="Countries"
      cacheOptions
      isDisabled={!isLoaded}
      options={virtualCentreOptions}
      components={animatedComponents}
      onChange={(data) =>
        handleClassObj(shortData(data), { name: "virtualCentres" })
      }
    />
  );
}
