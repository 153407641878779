import { useState, useRef, useEffect } from "react";

export default function SessionForm({
  activityList,
  addSessionDetails,
  setShowNewModal,
  mode,
}) {
  const [titleCharCount, setTitleCharCount] = useState(0);
  const [descCharCount, setDescCharCount] = useState(0);
  const [formData, setFormData] = useState({
    session_name: "",
    session_description: "",
  });
  const [editMode, setEditMode] = useState();
  const nameRef = useRef();
  const descRef = useRef();

  //function to check if any object in activityList has the property class_id
  function checkActivityListForClassId() {
    return activityList.some((item) => item.class_id);
  }

  function checkEditMode() {
    if (mode === "edit") {
      if (checkActivityListForClassId()) {
        setEditMode("view");
      } else {
        setEditMode("edit");
      }
    } else {
      setEditMode("view");
    }
  }

  useEffect(() => {
    checkEditMode();
  }, [activityList]);

  function handleFormData(e) {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function checkList() {
    if (activityList.length === 1) {
      setFormData((prevState) => ({
        ...prevState,
        session_name: activityList[0].lesson.session_name,
        session_description: activityList[0].lesson.session_description,
      }));
    }
  }

  useEffect(() => {
    checkList();
  }, [activityList]);

  useEffect(() => {
    setTitleCharCount(formData.session_name?.length);
    setDescCharCount(formData.session_description?.length);
  }, [formData]);

  const updateLesson = async () => {
    const updatedActivityList = JSON.parse(JSON.stringify(activityList));
    for (const item of updatedActivityList) {
      formData.session_name
        ? Object.assign(item.lesson, { session_name: formData.session_name })
        : delete item.lesson.session_name;
      formData.session_description
        ? Object.assign(item.lesson, {
            session_description: formData.session_description,
          })
        : delete item.lesson.session_description;
      addSessionDetails(item.temp_id, item.lesson);
    }
    setShowNewModal(false);
  };

  function clearSessionDetails() {
    setFormData((prevState) => ({
      ...prevState,
      session_name: "",
      session_description: "",
    }));
  }

  return (
    <div>
      <h3>Session details {editMode === "edit" && "(Optional)"}</h3>
      {activityList.length > 1 && (
        <p className="warning-text">
          Please note: you have selected multiple classes, any current session
          details will be overwritten.
        </p>
      )}
      <div className="activity-session-form">
        <input
          ref={nameRef}
          name="session_name"
          id="session_name"
          maxLength={60}
          readOnly={editMode === "view"}
          className="session-input"
          placeholder="Session title ..."
          value={formData.session_name}
          onChange={(e) => handleFormData(e)}
          required
        />
        <div className="char-count-div">{titleCharCount || 0}/60</div>

        <textarea
          ref={descRef}
          placeholder="Session description ..."
          rows="9"
          maxLength={500}
          readOnly={editMode === "view"}
          name="session_description"
          id="session_description"
          value={formData.session_description}
          className="schedule-form-strand-textarea session-input"
          onChange={(e) => handleFormData(e)}
        />
        <div className="char-count-div">{descCharCount || 0}/500</div>
        <div className="form-action-button-div">
          {editMode === "edit" && (
            <>
              <button
                name="save_button"
                id="save_button"
                className="schedule-form-button"
                onClick={updateLesson}
              >
                Save
              </button>
              <button
                name="clear_button"
                id="clear_button"
                className="schedule-form-button"
                onClick={clearSessionDetails}
              >
                Clear
              </button>
            </>
          )}
          <button
            name="close_button"
            id="close_button"
            className="schedule-form-button"
            onClick={() => setShowNewModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
